<template>
    <div class="box">
        <div class="box-header with-border">
            <h3>
                <previous-route-block/>

                {{ page_title }}
            </h3>
        </div>

        <div class="box-body">
            <form @keydown="form.onKeydown($event)" @submit.prevent="saveNews">

                <vue-tabs class="panel">
                    <v-tab :key="index"
                           :title="locale.toUpperCase()"
                           class="nav-tab-content"
                           v-for="(locale, index) in locales">
                        <div class="form-group">
                            <div class="row">
                                <label class="col-md-2 control-label" for="title">
                                    Заголовок
                                    <small>({{ locale.toUpperCase() }})</small>
                                    <span class="req"/>
                                </label>
                                <div class="col-md-10"
                                     v-bind:class="{ 'has-error': form.errors.has(locale + '.title') }">
                                    <input class="form-control"
                                           id="title"
                                           required
                                           type="text"
                                           v-model="form[locale].title">
                                    <HasError :field="locale + '.title'" :form="form"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <label class="col-md-2 control-label" for="description">
                                    Краткое описание
                                    <small>({{ locale.toUpperCase() }})</small>
                                    <span class="req"/>
                                </label>
                                <div class="col-md-10"
                                     v-bind:class="{ 'has-error': form.errors.has(locale + '.description') }">
                                            <textarea class="form-control"
                                                      id="description"
                                                      rows="3"
                                                      v-model="form[locale].description">
                                            </textarea>
                                    <HasError :field="locale + '.description'" :form="form"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-12"
                                     v-bind:class="{ 'has-error': form.errors.has(locale + '.content') }">
                                    <label>
                                        Содержание
                                        <small>({{ locale.toUpperCase() }})</small>
                                        <span class="req"/>
                                    </label>
                                    <editor :api-key="editorKey"
                                            :init="editorConfig"
                                            v-model="form[locale].content"/>
                                    <HasError :field="locale + '.content'" :form="form"/>
                                </div>
                            </div>
                        </div>
                    </v-tab>
                </vue-tabs>

                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <vue-dropzone :options="dropzoneOptions"
                                          id="dropzone"
                                          v-on:vdropzone-canceled="removeFile"
                                          v-on:vdropzone-error="handleFileErrors"
                                          v-on:vdropzone-file-added="loadFile"
                                          v-on:vdropzone-removed-file="removeFile">
                            </vue-dropzone>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="image-box">
                                <img :src="file_preview" alt=""
                                     class="img-fluid" v-if="file_preview"/>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

                <Button :disabled="!validateData || form.busy"
                        :form="form"
                        :loading="form.busy"
                        class="btn btn-lg btn-success pull-right"
                        style="min-width: 100px;">
                    <i class="fa fa-save"></i>
                </Button>
            </form>
        </div>
    </div>
</template>

<script>
    import Form from 'vform';
    import {VTab, VueTabs} from 'vue-nav-tabs';
    import 'vue-nav-tabs/themes/vue-tabs.css';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import Editor from '@tinymce/tinymce-vue';
    import EditorConfigData from './../../assets/data/tinymce-editor-config.json';
    import dropzone_config from './../../assets/data/dropzone-config.json';
    import LocaleService from "../../services/LocaleService";

    Form.axios = API.apiClient;

    export default {
        name: 'create',

        components: {
            Button, HasError, AlertError, AlertErrors,
            VueTabs,
            VTab,
            vueDropzone: vue2Dropzone,
            'editor': Editor,
        },

        metaInfo() {
            return {
                title: this.page_title,
            }
        },

        data: () => ({
            page_title: 'Добавить новость',

            form: new Form({
                file: null,
            }),

            locales: [],

            file_preview: null,
            dropzoneOptions: dropzone_config,

            editorKey: process.env.VUE_APP_TINYMCE_API_KEY,
            editorConfig: EditorConfigData,
        }),

        async created() {
            try {
                this.locales = await LocaleService.getItems();

                this.locales.forEach(locale => {
                    this.form[locale] = {
                        title: null,
                        description: null,
                        content: null,
                    }
                });

                this.dropzoneOptions.url = '/news/add';
                this.dropzoneOptions.maxFiles = 1;
                this.dropzoneOptions.disablePreviews = true;
                this.dropzoneOptions.createImageThumbnails = false;
            } catch (error) {
                errorHandler(error);
            }
        },

        computed: {
            validateData: function () {
                return this.locales && this.locales.length > 0;
            },
        },

        methods: {
            loadFile(file) {
                this.form.file = file;
                this.file_preview = URL.createObjectURL(file);
                this.dropzoneOptions.clickable = false;
            },
            removeFile() {
                this.form.file = null;
                this.file_preview = null;
                this.dropzoneOptions.clickable = true;
            },
            async saveNews() {
                await this.form.post('/news/add').then(() => {
                    showSuccess();
                    setTimeout(() => this.$router.push('/news'), 600);
                }).catch((error) => {
                    errorHandler(error);
                });
            },
            handleFileErrors(file = null, message) {
                errorHandler(message);
            },
        }
    }
</script>

<style scoped>
    .image-box {
        position: relative;
    }

    .image-box img {
        width: 100%;
        height: auto;
    }
</style>